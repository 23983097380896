const submenuParent = document.querySelector(".menu-item-2482");
const submenuParentEng = document.querySelector(".menu-item-2926");

if (window.innerWidth > 1259) {
  if (submenuParent) {
    const submenuAnchors = document.querySelector(".submenu-anchors-pl");
    const submenu = document.querySelector(".sub-menu");

    submenuParent.appendChild(submenuAnchors);
    submenuAnchors.style.display = "flex";

    submenu.childNodes.forEach((item) => {
      if (item.nodeName === "LI") {
        item.addEventListener("mouseover", () => {
          const submenuAnchors = document.querySelectorAll(
            ".submenu-anchor-pl"
          );
          const currentAnchors = document.querySelector(
            `#${item.textContent.split(" ").join("")}`
          );

          submenuAnchors.forEach((anchor) => {
            anchor.style.display = "none";
          });

          currentAnchors.style.display = "block";
        });
      }
    });
  } else if (submenuParentEng) {
    const submenuAnchors = document.querySelector(".submenu-anchors-eng");
    const submenu = document.querySelector(".sub-menu");

    submenuParentEng.appendChild(submenuAnchors);
    submenuAnchors.style.display = "flex";

    submenu.childNodes.forEach((item) => {
      if (item.nodeName === "LI") {
        item.addEventListener("mouseover", () => {
          const submenuAnchors = document.querySelectorAll(
            ".submenu-anchor-eng"
          );
          const currentAnchors = document.querySelector(
            `#${item.textContent.split(" ").join("")}`
          );

          submenuAnchors.forEach((anchor) => {
            anchor.style.display = "none";
          });

          currentAnchors.style.display = "block";
        });
      }
    });
  }
}
