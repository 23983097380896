const body = document.querySelector("body");
const link = window.location.href;

if (body.classList.contains("page-id-2475")) {
  if (link.includes("#form") === true) {
    const formPolish = document.querySelector(
      ".contactForm__shortcode--polish"
    );
    const formText = document.querySelector(".contactForm__text");
    if (formPolish) {
      formPolish.classList.add("contactForm__shortcode--active");
      formText.classList.remove("contactForm__text--disabled");
      formText.classList.add("contactForm__text--active");
    }
  }
} else if (body.classList.contains("page-id-2934")) {
  if (link.includes("#form") === true) {
    const formEnglish = document.querySelector(
      ".contactForm__shortcode--english"
    );
    const formText = document.querySelector(".contactForm__text");
    if (formEnglish) {
      formEnglish.classList.add("contactForm__shortcode--active");
      formText.classList.remove("contactForm__text--disabled");
      formText.classList.add("contactForm__text--active");
    }
  }
}

const clickableItem = document.querySelector(".contactData__item--clickable");
if (clickableItem) {
  clickableItem.addEventListener("click", () => {
    const html = document.querySelector("html");
    const formPolish = document.querySelector(
      ".contactForm__shortcode--polish"
    );
    const formEnglish = document.querySelector(
      ".contactForm__shortcode--english"
    );
    const formText = document.querySelector(".contactForm__text");
    if (html.lang === "en-US") {
      if (formEnglish) {
        formEnglish.classList.toggle("contactForm__shortcode--active");
      }
    } else {
      formPolish.classList.toggle("contactForm__shortcode--active");
    }
    formText.classList.remove("contactForm__text--disabled");
    formText.classList.toggle("contactForm__text--active");
  });
}
