const html = document.querySelector("html");

if (html.lang === "en-US") {
  const prefooterPolish = document.querySelector(".prefooter--polish");
  const prefooterEnglish = document.querySelector(".prefooter--english");
  const mainNavigation = document.querySelector(".main-navigation");
  const footerStudio = document.querySelector(".footer-studio");
  const rodoPl = document.querySelector(".rodo-pl");
  const rodoEng = document.querySelector(".rodo-eng");
  const reviewsButton = document.querySelector(".reviews__button");
  const newsletterTextPolish = document.querySelector(
    ".newsletter__text--polish"
  );
  const contactItemClickable = document.querySelector(
    ".contactData__item--clickable"
  );
  const newsletterRodoPolish = document.querySelector(
    ".newsletter-rodo-polish"
  );
  const newsletterRodoEnglish = document.querySelector(
    ".newsletter-rodo-english"
  );
  const buttonNewsletterPolish = document.querySelector(
    ".button-newsletter-polish"
  );
  const buttonNewsletterEnglish = document.querySelector(
    ".button-newsletter-english"
  );
  const newsletterFormInput = document.querySelector(".form-text-input");

  prefooterPolish.style.display = "none";
  prefooterEnglish.style.display = "block";
  mainNavigation.classList.add("english-center");
  footerStudio.childNodes[0].textContent = "Made by";
  rodoPl.style.display = "none";
  rodoEng.style.display = "block";
  if (reviewsButton) {
    reviewsButton.innerText = "See more opinions";
  }
  if (newsletterTextPolish) {
    newsletterTextPolish.style.display = "none";
    const newsletterTextEnglish = document.querySelector(
      ".newsletter__text--english"
    );
    newsletterTextEnglish.style.display = "block";
  }
  if (contactItemClickable) {
    const contactFormText = document.querySelector(".contactForm__text");
    contactItemClickable.childNodes[3].innerText = "Contact us";
    contactItemClickable.childNodes[5].innerText = "Use contact form";
    contactFormText.childNodes[1].innerText = "Write to us";
  }
  if (newsletterRodoPolish) {
    newsletterRodoPolish.style.display = "none";
  }
  if (newsletterRodoEnglish) {
    newsletterRodoEnglish.style.display = "block";
  }
  if (buttonNewsletterPolish) {
    buttonNewsletterPolish.style.display = "none";
  }
  if (buttonNewsletterEnglish) {
    buttonNewsletterEnglish.style.display = "block";
  }
  if (newsletterFormInput) {
    newsletterFormInput.placeholder = "Your e-mail";
  }
}
